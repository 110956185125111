<template>
  <!-- Table Container Card -->
  <b-overlay :show="loading" rounded="sm">
    <b-card no-body>
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="9"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <div>
              <label for="">Por página</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector mr-1"
              />
            </div>
            <div class="mt-2">
              <b-button variant="success" class="btn-icon mr-1" @click="get">
                <feather-icon icon="RefreshCwIcon" size="16" />
              </b-button>
            </div>

            <div
              class="mt-2"
              v-if="
                (user_state && user_state.role == 'admin') ||
                  permissions.includes('create_service')
              "
            >
              <b-button
                @click="storeEditService = true"
                variant="gradient-info"
              >
                <feather-icon icon="PlusIcon" size="16" />
                Nuevo servicio
              </b-button>
            </div>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="3">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="search"
                @keyup.enter="get"
                class="d-inline-block mr-1"
                placeholder="Buscar..."
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refInvoiceListTable"
        :items="services"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No se encontraron registros"
        class="position-relative"
      >
        <template #cell(tax_type)="data">
          {{ data.item.tax_type.name }}
        </template>

        <template #cell(price)="data">
          {{ formatCurrency(data.item.price) }}
        </template>

        <template #cell(total)="data">
          {{ formatCurrency(data.item.total) }}
        </template>

        <template #cell(active)="data">
          <b-form-checkbox
            :disabled="
              !(user_state && user_state.role == 'admin') &&
                !permissions.includes('edit_service')
            "
            v-model="data.item.active"
            class="custom-control-primary"
            switch
            @change="changeStatusService(data.item)"
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="d-flex justify-content-start">
            <b-dropdown
              class="ml-5"
              variant="link"
              toggle-class="p-0"
              no-caret
              right
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="20"
                  class="text-body"
                />
              </template>

              <b-dropdown-item
                v-if="
                  (user_state && user_state.role == 'admin') ||
                    permissions.includes('edit_service')
                "
                @click="editService(data.item)"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Editar</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="
                  (user_state && user_state.role == 'admin') ||
                    permissions.includes('delete_service')
                "
                @click="confirmDelete(data.item.id)"
              >
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Eliminar</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted" v-if="totalRegisters > 0"
              >Mostrando {{ fromRegister }} a {{ toRegister }} de
              {{ totalRegisters }} registros
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-if="services.length !== totalRegisters"
              v-model="currentPage"
              :total-rows="totalRegisters"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>

      <b-sidebar
        shadow
        bg-variant="white"
        backdrop
        width="30%"
        right
        :title="`${flagEdit ? 'Editar servicio' : 'Registrar servicio'}`"
        :visible="storeEditService"
        @hidden="storeEditService = false"
      >
        <div class="p-2">
          <form-service
            :service="service"
            :edit="flagEdit"
            @formAction="formServiceAction"
          />
        </div>
      </b-sidebar>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BPagination,
  BOverlay,
  BSidebar,
  BDropdown,
  BDropdownItem,
  BFormCheckbox
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { confirmAlert, currencyFormat } from "@/helpers";

import serviceClass from "@/services/services.service";
import FormService from "@/components/FormService";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapState } from "vuex";

export default {
  directives: {
    Ripple
  },
  components: {
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
    vSelect,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BOverlay,
    BSidebar,
    FormService
  },
  data() {
    return {
      loading: false,
      perPage: 10,
      perPageOptions: [10, 20, 30, 40, 50, 100],
      search: "",
      currentPage: 1,
      lastPage: 1,
      totalRegisters: 1,
      toRegister: 10,
      fromRegister: 1,
      flagEdit: false,
      storeEditService: false,

      services: [],
      tableColumns: [
        { key: "cabys_code", label: "Código Cabys", sortable: true },
        { key: "name", label: "Nombre", sortable: true },
        { key: "price", label: "Precio", sortable: true },
        { key: "tax_type", label: "Iva", sortable: true },
        { key: "total", label: "Total", sortable: true },
        { key: "active", label: "Activo", sortable: true },
        { key: "actions", label: "Acciones", tdClass: "p-0 pr-1" }
      ],

      service: {
        name: "",
        description: "",
        cabys_code: "",
        tax: 0,
        price: "",
        active: true
      }
    };
  },
  computed: {
    ...mapState({
      user_state: state => state.app.user,
      permissions: state => state.app.permissions
    })
  },
  watch: {
    storeEditService(newval) {
      if (!newval) {
        this.flagEdit = false;
        this.service = { ...this.$options.data().service };
      }
    },
    currentPage(newval) {
      this.get(newval);
    },
    perPage() {
      this.get(this.currentPage);
    }
  },
  methods: {
    formatCurrency(amount) {
      return currencyFormat(amount);
    },
    async get(page = 1, searchQuery = {}) {
      try {
        this.loading = true;
        searchQuery.perPage = this.perPage;
        searchQuery.term = this.search;
        const { data: res } = await serviceClass.getServices(page, searchQuery);
        this.services = res.data;
        this.loading = false;
        //Pagination
        this.lastPage = res.last_page;
        this.toRegister = res.to;
        this.fromRegister = res.from;

        this.totalRegisters = res.total;
      } catch (error) {
        this.loading = false;
      }
    },
    async changeStatusService(service) {
      const { data: res } = await serviceClass.changeStatusService({
        active: service.active,
        id: service.id
      });
      if (!res.success) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: res.message,
            icon: "XIcon",
            variant: "danger"
          }
        });
      } else {
        this.$store.dispatch("app/getGeneralData");
      }
    },
    editService(item) {
      const iva = this.$store.state.app.taxes.findIndex(
        i => i.id == item.tax_type_id
      );
      this.service = JSON.parse(JSON.stringify(item));

      this.service.tax = this.$store.state.app.taxes[iva];
      this.service.cabys_code = item.cabys_code_hacienda;

      this.flagEdit = true;
      this.storeEditService = true;
    },
    formServiceAction(data) {
      if (data.type == "created") this.services.unshift(data.data);
      if (data.type == "updated") {
        const index = this.services.findIndex(i => i.id == data.data.id);
        this.services[index] = data.data;
        this.services = [...this.services];
      }
      this.storeEditService = false;
    },
    async confirmDelete(id) {
      try {
        const confirm = await confirmAlert(
          "Eliminar servicio",
          "Vas a eliminar el servicio, ¿deseas continuar?"
        );
        if (!confirm.isConfirmed) return;

        const { data: res } = await serviceClass.deleteService({
          id: id
        });

        if (res.success) {
          const index = this.services.findIndex(i => i.id === id);
          this.services.splice(index, 1);
          this.$store.dispatch("app/getGeneralData");
        }
      } catch (error) {
        console.log(error);
      }
    }
  },
  created() {
    this.get();
  }
};
</script>
